<template>
  <div class="footWrap">
    <div class="startFoot" v-if="$route.path == '/start'">
      <img
        v-lazy="require('@/assets/images/startBg.png')"
        alt=""
        class="startBg"
      />
      <div class="footDec">
        <Icons />
        <FootText />
      </div>
    </div>
    <div class="auctionFoot" v-else-if="$route.path == '/auction'">
      <img
        v-lazy="require('@/assets/images/auctionBg.png')"
        class="auctionBg"
      />
      <div class="auctionFootCons">
        <div class="breeds">
          The breeds of Genesis Horse have six different genetic attributes.
          <br />
          Darley Arabian has the finest level for all 6 attributes, Godolphin
          Arabian has 5 attributes at the<br />
          finest level, Byerly Turk has 4 finest, and Nakamoto Satoshi has 3.
          All the genetic <br />attributes of Genesis horses are generated
          randomly.
        </div>
        <img
          v-lazy="require('@/assets/images/footLogo.png')"
          alt=""
          class="footLogo"
        />
        <Icons />
        <FootText />
      </div>
    </div>
    <div class="footer" v-else>
      <div class="foot_search">
        <div class="container">
          <img
            v-lazy="require('@/assets/images/footHouse.png')"
            class="footHouse"
          />
          <div class="search_wrap">
            <div class="search_title">METARACE® HORSE RACING</div>
            <!-- <div class="search_con">
              <div class="email">Email</div>
              <input type="text" class="inp" v-model="emailVal" />
              <div class="inpTips">
                Subscribe. Stay up to date with our latest news and releases!
              </div>
            </div>
            <div class="searchBtn" @click="subscribe">SUBSCRIBE</div> -->
            <form action="https://metarace.us20.list-manage.com/subscribe/post?u=703596e2fd244e10f2ad20649&amp;id=b7441adf38" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate search_con" target="_blank" novalidate>
              <div class="email">Email</div>
              <input type="email" value="" name="EMAIL" class="required email inp" id="mce-EMAIL">
              <div class="response" id="mce-error-response" style="display:none"></div>
              <div class="response" id="mce-success-response" style="display:none"></div>
              <div class="inpTips">
                Subscribe. Stay up to date with our latest news and releases!
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                  <input type="text" name="b_703596e2fd244e10f2ad20649_b7441adf38" tabindex="-1" value="">
              </div>
              <input type="submit" value="SUBSCRIBE" name="subscribe" id="mc-embedded-subscribe" class="button searchBtn">
            </form>
            <Icons />
          </div>
        </div>
      </div>
      <FootText />
    </div>
  </div>
</template>

<script>
import Icons from "./Icons.vue";
import FootText from "./FootText.vue";

export default {
  components: {
    Icons,
    FootText,
  },
  data() {
    return {
      emailVal: "",
    };
  },
  mounted() {
    const oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    oScript.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    document.body.appendChild(oScript);
  },
  methods: {
    async subscribe() {
      if (!this.emailVal) return;
      const res = await this.$axios.post(`/smtp/sendEmil`, {
        domain: "metarace",
        to: "info.metarace@gmail.com",
        title: "SUBSCRIBE",
        content: this.emailVal,
      });
      let { status } = res;
      if (status === 200) {
        this.$message({
          // data.data
          message: "Sent successfully",
          type: "success",
        });
      }
    },
  },
};
</script>

<style scoped lang='scss'>

.footer {
  background: linear-gradient(90deg, #640c96 0%, #0e012a 58%, #080018 100%);
  padding-bottom: 88px;
  .foot_search {
    width: 100%;
    height: 760px;
    background: url("../assets/images/footBg.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 129px;
    .container {
      width: 1200px;
      height: 100%;
      padding: 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      .footHouse {
        width: 490px;
        height: 970px;
        // margin-top: -112px;
        position: relative;
        top: -112px;
        z-index: 999;
      }
      .search_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .search_title {
          font-weight: 800;
          font-size: 44px;
          font-family: "Rubik-BoldItalic";
        }
        .search_con {
          margin-top: 36px;
          .inp {
            width: 598px;
            outline: none;
            height: 85px;
            border: 3px solid #c822ff;
            margin: 15px 0;
            background: transparent;
            text-indent: 15px;
            color: #fff!important;
            font-size: 36px;
            font-family: "Rubik-Italic";
          }
          .inp:focus {
            outline: none;
            border: 3px solid #c822ff;
          }

          .email,
          .inpTips {
            color: #aaa;
            font-size: 16px;
          }
          .inpTips {
            font-size: 12px;
            font-family: Rubik-Italic;
          }
          .email {
            font-size: 32px;
            font-family: Rubik-MediumItalic;
          }
          .searchBtn {
            width: 228px;
            height: 66px;
            border: 3px solid #ffffff;
            background-color: transparent;
            text-align: center;
            line-height: 66px;
            font-size: 33px;
            font-family: Rubik-BoldItalic;
            cursor: pointer;
            margin-top: 113px;
            margin-bottom: 94px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.startFoot {
  width: 100%;
  .startBg {
    width: 100%;
    height: 676px;
    display: block;
  }
  .auctionBg {
    width: 100%;
    height: 1235px;
    display: block;
  }
  .footDec {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #070707;
    padding-bottom: 207px;
  }
}

.auctionFoot {
  width: 100%;
  position: relative;
  .auctionBg {
    width: 100%;
    height: 1235px;
    position: absolute;
    left: 0;
    top: -50%;
    z-index: 99;
  }
  .auctionFootCons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -76px;
    z-index: 99;
    .breeds {
      width: 968px;
      height: 92px;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
      font-family: "Rubik-MediumItalic";
    }
    .footLogo {
      width: 342px;
      height: 342px;
      margin-bottom: 43px;
      margin-top: 109px;
    }
  }
}
</style>