import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import VueLazyLoad from 'vue-lazyload';

export const eventsBus = new Vue()

const service = axios.create({
  baseURL: '/',
  // timeout:"3000"
})
service.interceptors.response.use(
  response => { 
    return response
  },
  error => {
    if (error.request.status == "500") {
      console.log(error)
    }
    
    return Promise.reject(error)
  }
)

Vue.prototype.$axios= service
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueLazyLoad);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
