<template>
  <div class="icons">
    <a href="https://discord.gg/F9QZEpHSBE" target="_blank"
      ><img v-lazy="require('@/assets/images/footIcon1.png')"
    /></a>
    <a href="https://twitter.com/MetaRace" target="_blank"
      ><img v-lazy="require('@/assets/images/footIcon2.png')"
    /></a>
    <a href="https://www.facebook.com/MetaRaceNFT" target="_blank"
      ><img v-lazy="require('@/assets/images/footIcon3.png')"
    /></a>
    <a href="https://metarace.medium.com/" target="_blank"
      ><img v-lazy="require('@/assets/images/footIcon4.png')"
    /></a>
    <a
      href="https://www.youtube.com/channel/UC8H1d-4SPcEFMY2MhZWHiuw"
      target="_blank"
      ><img v-lazy="require('@/assets/images/footIcon5.png')"
    /></a>
    <a href="https://t.me/MetaRace" target="_blank"
      ><img v-lazy="require('@/assets/images/footIcon6.png')"
    /></a>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang='scss'>
.icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  img {
    height: 57px;
    width: 56px;
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>