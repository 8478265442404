<template>
	<el-container>
		<el-header style="height: 125px" ref="Header">
			<Header />
		</el-header>
		<el-main>
			<router-view />
		</el-main>
		<el-footer style="height: auto">
			<Footer />
		</el-footer>
	</el-container>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Foot'

export default {
	components: {
		Header,
		Footer
	},
	data() {
		return {
			logo: require("../assets/logo.png"),

		}
	},
	created() {

	},
	filters: {

	},
	methods: {
		
	}
}
</script>

<style scoped lang='scss'>
.el-container{
	min-width: 1200px;
}
.el-header,
.el-main,
.el-footer {
	padding: 0;
	background: linear-gradient(90deg, #640C96 0%, #0E012A 57.99999999999999%, #080018 100%);
}
</style>
