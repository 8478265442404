"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ethers_1 = require("ethers");
var ethereum = window.ethereum;
var web3 = window.web3;
var MetaRaceSDKContract = {
    getChainId: function () { return __awaiter(void 0, void 0, void 0, function () {
        var chainId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ethereum.request({ method: 'eth_chainId' })];
                case 1:
                    chainId = _a.sent();
                    return [2 /*return*/, Number(chainId)];
            }
        });
    }); },
    checkStatus: function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!web3 || !ethereum) {
                        window.alert('Failed to connect wallet. Please refresh the page or exit to try again!');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, MetaRaceSDKContract.connectReqAccount()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); },
    connectReqAccount: function () { return __awaiter(void 0, void 0, void 0, function () {
        var newAccounts, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ethereum.request({
                            method: 'eth_requestAccounts',
                        })];
                case 1:
                    newAccounts = _a.sent();
                    return [2 /*return*/, newAccounts[0]];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [2 /*return*/, error_1];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    waitFun: function (tx, message, callbackFun) { return __awaiter(void 0, void 0, void 0, function () {
        var receipt;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, tx.wait(2)];
                case 1:
                    receipt = _a.sent();
                    if (receipt.status === 1) {
                        if (callbackFun) {
                            callbackFun(receipt.transactionHash);
                        }
                        return [2 /*return*/, {
                                code: receipt.status,
                                message: message,
                                tx_hash: receipt.transactionHash
                            }];
                    }
                    return [2 /*return*/];
            }
        });
    }); },
    errorFun: function (error) {
        if (error.data) {
            // let { data: { message, code } } = error
            console.log(error.data);
            return error.data;
        }
        else if (error.code === 4001) {
            return {
                code: 4001,
                message: "MetaMask Tx Signature: User denied transaction signature."
            };
        }
        else {
            console.log(error.message);
            return error;
        }
    },
    /**
     * @param address
     * @param abi
     * @returns
     */
    getContracts: function (address, abi) {
        var ethersProvider = new ethers_1.ethers.providers.Web3Provider(ethereum);
        return new ethers_1.ethers.Contract(address, abi, ethersProvider.getSigner());
    },
    getAllowance: function (contractObj, address) { return __awaiter(void 0, void 0, void 0, function () {
        var account, allowance, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, MetaRaceSDKContract.connectReqAccount()];
                case 1:
                    account = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, contractObj.allowance(account, address)];
                case 3:
                    allowance = _a.sent();
                    return [2 /*return*/, allowance];
                case 4:
                    error_2 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_2)];
                case 5: return [2 /*return*/];
            }
        });
    }); },
    /**
     * @param contractObj
     * @param coinId
     * @returns balance Bignumber Obj
     */
    getBalance: function (contractObj) { return __awaiter(void 0, void 0, void 0, function () {
        var account, balance, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, MetaRaceSDKContract.connectReqAccount()];
                case 1:
                    account = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, contractObj.balanceOf(account)];
                case 3:
                    balance = _a.sent();
                    return [2 /*return*/, balance];
                case 4:
                    error_3 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_3)];
                case 5: return [2 /*return*/];
            }
        });
    }); },
    /**
     * @param contractObj
     * @param address
     * @param updataCallback
     */
    handleApprove: function (contractObj, address, updataCallback) { return __awaiter(void 0, void 0, void 0, function () {
        var tx, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.approve(address, ethers_1.ethers.constants.MaxUint256)];
                case 1:
                    tx = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.waitFun(tx, '', updataCallback)];
                case 2:
                    error_4 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_4)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    nftTotalSupply: function (contractObj, nft, type) { return __awaiter(void 0, void 0, void 0, function () {
        var totalSupply, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.nftTotalSupply(nft, type)];
                case 1:
                    totalSupply = _a.sent();
                    return [2 /*return*/, totalSupply];
                case 2:
                    error_5 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_5)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    nftReminder: function (contractObj, nft, type) { return __awaiter(void 0, void 0, void 0, function () {
        var reminder, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.nftReminder(nft, type)];
                case 1:
                    reminder = _a.sent();
                    return [2 /*return*/, reminder];
                case 2:
                    error_6 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_6)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    nftPrice: function (contractObj, nft, type, token) { return __awaiter(void 0, void 0, void 0, function () {
        var price, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.nftPrice(nft, type, token)];
                case 1:
                    price = _a.sent();
                    return [2 /*return*/, price];
                case 2:
                    error_7 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_7)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    buy: function (contractObj, nft, token, type) { return __awaiter(void 0, void 0, void 0, function () {
        var tx, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.buy(nft, token, type)];
                case 1:
                    tx = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.waitFun(tx, '')];
                case 2:
                    error_8 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_8)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    /**
     * 文本签名
     * @param msgParams [{type: 'string',name: 'Message',value: 'Hi'},{type: 'uint32',name: 'A number',value: 123}]
     * @param callback function callback(res){}
     */
    signMessage: function (msgParams, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var from, params, method;
        return __generator(this, function (_a) {
            from = web3.eth.accounts[0];
            if (!from)
                MetaRaceSDKContract.checkStatus();
            console.log('CLICKED, SENDING PERSONAL SIGN REQ');
            params = [msgParams, from];
            console.dir(params);
            method = 'eth_signTypedData';
            web3.currentProvider.sendAsync({
                method: method,
                params: params,
                from: from,
            }, function (err, result) {
                if (err)
                    return console.dir(err);
                if (result.error) {
                    alert(result.error.message);
                }
                if (result.error)
                    return console.error(result);
                console.log('PERSONAL SIGNED:' + JSON.stringify(result.result));
                callback(JSON.stringify(result.result));
            });
            return [2 /*return*/];
        });
    }); },
    SignMessage_test: function (msgParams, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var from, params, method;
        return __generator(this, function (_a) {
            from = web3.eth.accounts[0];
            if (!from)
                MetaRaceSDKContract.checkStatus();
            console.log('CLICKED, SENDING PERSONAL SIGN REQ');
            params = [from, msgParams];
            console.dir(params);
            method = 'personal_sign';
            web3.currentProvider.sendAsync({
                method: method,
                params: params,
                from: from,
            }, function (err, result) {
                if (err)
                    return console.dir(err);
                if (result.error) {
                    alert(result.error.message);
                }
                if (result.error)
                    return console.error(result);
                console.log('PERSONAL SIGNED:' + JSON.stringify(result.result));
                callback(JSON.stringify(result.result));
            });
            return [2 /*return*/];
        });
    }); },
    sign_jwt_token: function (msgParams, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var from, params, method;
        return __generator(this, function (_a) {
            from = web3.eth.accounts[0];
            if (!from)
                MetaRaceSDKContract.checkStatus();
            console.log('CLICKED, SENDING PERSONAL SIGN REQ');
            params = [from, msgParams];
            console.dir(params);
            method = 'personal_sign';
            web3.currentProvider.sendAsync({
                method: method,
                params: params,
                from: from,
            }, function (err, result) {
                if (err)
                    return console.dir(err);
                if (result.error) {
                    alert(result.error.message);
                }
                if (result.error)
                    return console.error(result);
                console.log('PERSONAL SIGNED:' + JSON.stringify(result.result));
                callback(JSON.stringify(result.result));
            });
            return [2 /*return*/];
        });
    }); },
    getSoldPrice: function (contractObj, name, address) { return __awaiter(void 0, void 0, void 0, function () {
        var price, error_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.soldPrice(name, address)];
                case 1:
                    price = _a.sent();
                    return [2 /*return*/, price];
                case 2:
                    error_9 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_9)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getDiscountPrice: function (contractObj, name, address) { return __awaiter(void 0, void 0, void 0, function () {
        var price, error_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.discountPrice(name, address)];
                case 1:
                    price = _a.sent();
                    return [2 /*return*/, price];
                case 2:
                    error_10 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_10)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getProbability: function (contractObj, name, type) { return __awaiter(void 0, void 0, void 0, function () {
        var error_11;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.probability(name, type)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_11 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_11)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    buyAndOpenFun: function (contractObj, uuid, name, address) { return __awaiter(void 0, void 0, void 0, function () {
        var tx, error_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj["buyAndOpen(string,string,address)"](uuid, name, address)];
                case 1:
                    tx = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.waitFun(tx, '')];
                case 2:
                    error_12 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_12)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getOpenResult: function (contractObj, uuid) { return __awaiter(void 0, void 0, void 0, function () {
        var error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.getOpenResult(uuid)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_13 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_13)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    isSoldOut: function (contractObj, name) { return __awaiter(void 0, void 0, void 0, function () {
        var error_14;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.isSoldOut(name)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_14 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_14)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    isLimitBuy: function (contractObj, account) { return __awaiter(void 0, void 0, void 0, function () {
        var error_15;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.isLimitBuy(account)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_15 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_15)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getLimitCount: function (contractObj, account) { return __awaiter(void 0, void 0, void 0, function () {
        var error_16;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.getLimitCount(account)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_16 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_16)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getBoxCount: function (contractObj, name) { return __awaiter(void 0, void 0, void 0, function () {
        var error_17;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, contractObj.getBoxCount(name)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    error_17 = _a.sent();
                    return [2 /*return*/, MetaRaceSDKContract.errorFun(error_17)];
                case 3: return [2 /*return*/];
            }
        });
    }); },
};
exports.default = MetaRaceSDKContract;
