<template>
  <div class="header">
    <div class="heiderWrap">
      <img class="logo" src="../assets/logo.png" alt @click="scrollTop()" />
      <ul class="items">
        <li
          @click="scrollTop()"
          :class="($route.path == '/' || $route.path == '/index' || $route.path == '/start') && 'currentItem'"
        >
          Home
        </li>
        <li
          @click="navClick('mysteryBox')"
          :class="$route.path == '/mysteryBox' && 'currentItem'"
        >
          Marketplace
        </li>
        <li
          @click="refPosition('Gameplay')"
          :class="currentItem == 'course' && 'currentItem'"
        >
          Gameplay
        </li>
        <li @mouseover="flag = true" @mouseleave="flag = false" class="downMenu">
          About Us<i class="el-icon-arrow-down"></i>
          <div class="aboutWrap" v-if="flag">
            <div @click="navClick('documents')">White Paper</div>
            <div @click="refPosition('Tokenomics')">Tokenomics</div>
            <div @click="refPosition('Team')">Team</div>
            <div @click="refPosition('Milestone')">Milestone</div>
          </div>
        </li>
        <li
          v-if="$route.path == '/mysteryBox'"
          @click="connect"
          :class="`connectBtn ${$route.path == '/mysteryBox' && 'currentItem'}`"
        >
          Connect Wallet
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MetaRaceSDK from '@/utils/MetaRaceSDK'
import {eventsBus} from '../main'

export default {
  name: "Header",

  data() {
    return {
      currentItem: "home",
      flag: false,
    };
  },
  mounted(){
    
  },
  methods: {
    async connect(){
      let {account, env, chainId, msg} = await MetaRaceSDK.CheckStatus()
      if(account && typeof(msg) === 'string'){
        this.$message.error(msg);
        return false
      }else{
        this.$message.success('Connected');
        eventsBus.$emit('checkColorType', 'PLATINUM')
        eventsBus.$emit('initBoxData', 'PLATINUM')
      }
    },
    scrollTop(){
      this.$router.push({ path: "/" });
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    },
    navClick(item) {
      this.currentItem = item;
      switch (item) {
        case "auction":
          this.$router.push({ path: "/auction" });
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
          })
          break;
        case "mysteryBox":
          this.$router.push({ path: "/mysteryBox" });
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
          })
          break;
        case "documents":
          window.open('./MetaRace_HorseRacing_WhitePaper_V2.pdf')
          break;
        default:
          break;
      }
    },

    refPosition(name) {
      this.$router.push({ path: `/index#${name}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  height: 125px;
  width: 100%;
  background: #0C0B0E;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.heiderWrap {
  display: flex;
  width: 90%;
  max-width: 1720px;
  height: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 59px;
  width: 213px;
}
.items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  font-size: 20px;
  height: 100%;
}
.items li {
  width: 148px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Rubik-BoldItalic;
  i {
    color: #c822ff;
    margin-left: 15px;
  }
}
.items li:hover{
  color: #c822ff;
}
.items .connectBtn{
  width: 171px;
  height: 41px;
  border: 3px solid #C822FF;
  font-family: Rubik-BoldItalic;
  font-size: 20px;
  margin-left: 12px;
}
// .items li:last-child {
//   width: 115px;
//   height: 41px;
//   border: 3px solid #c822ff;
//   line-height: 41px;
//   color: #c822ff;
//   /* background: linear-gradient(225deg, #ca00fc 0%, #ff6eed 100%);
//   box-shadow: 0px 0px 10px 0px rgba(207, 11, 251, 0.38); */
//   border-radius: 4px;
//   margin-left: 48px;
// }
.items .downMenu {
  position: relative;
  .aboutWrap {
    width: 183px;
    height: 225px;
    background: RGBA(35, 29, 45, 0.8);
    padding: 25px 22px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 125px;
    line-height: 2.2;
    border-radius: 3px;
    z-index: 9999;
    div,a {
      color: #fff;
      text-decoration: none;
    }
    div:hover {
      color: #c822ff;
    }
  }
}
.currentItem {
  color: #c822ff;
}
</style>
