import Vue from 'vue'
import Layout from '@/layout'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const scrollBehavior = function (to) {
  if (to.hash) {
    return {
      selector: to.hash
    }
  }
}

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/Index'),
        name: 'index',
        meta: { title: 'Index' }
      },
      {
        path: 'auction',
        component: () => import('@/views/auction/index'),
        name: 'auction',
        meta: { title: 'Auction' }
      },
      {
        path: 'game',
        component: () => import('@/views/game/index'),
        name: 'game',
        meta: { title: 'Game' }
      },
      {
        path: 'mysteryBox',
        component: () => import('@/views/mysteryBox/index'),
        name: 'mysteryBox',
        meta: { title: 'MysteryBox' }
      },
      {
        path: 'start',
        component: () => import('@/views/start/index'),
        name: 'start',
        meta: { title: 'Start' }
      },
      {
        path: 'getToken',
        component: () => import('@/views/getToken/index'),
        name: 'getToken',
        meta: { title: 'GetToken' }
      }
    ],
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

export default router
